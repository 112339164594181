import React from "react"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"

const ErrorPage = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <Seo
        title="Hvala za prijavo"
        lang="sl"
        description="Lightroom Mobile Presets - Urejaj slike z enim klikom - Top kvaliteta in doživljenski dostop. 24/7 podpora."
      />
      <section className="max-w-screen-xl mx-auto px-4 py-10 sm:py-16">
        <div className="mb-4">
          <p className="text-center text-2xl mb-3 font-semibold">
            Hvala za prijavo na brezplačen Lightroom Mobile & Presets tečaj.
          </p>
          <hr className="w-20  border-primary-100 mx-auto" />
        </div>
        <div className="max-w-lg mx-auto">
          <p className="mb-4">
            V nekaj minutah ti bom poslala email kjer dobiš dodatne informacije
            o tečaju in kako bo potekal.
          </p>

          <p className="font-medium">
            P. S. V primeru, da še nimaš mojih brezplačnih presetov ti spodaj
            prilagam povezave do njih.{" "}
            <span role="img" aria-label="arrow down">
              ⬇️
            </span>
          </p>
          <ul className="mt-3">
            <li className="py-2">
              <a
                href="https://italiana-freebie.lilicadez.com"
                target="_blank"
                rel="noreferrer"
                className="underline text-blue-600"
              >
                Italiana Freebie{" "}
                <span role="img" aria-label="pizza">
                  🍕
                </span>
              </a>
            </li>
            <li className="py-2">
              <a
                href="https://cinematica-freebie.lilicadez.com"
                target="_blank"
                rel="noreferrer"
                className="underline text-blue-600"
              >
                Cinematica Freebie{" "}
                <span role="img" aria-label="film roll">
                  🎞
                </span>
              </a>
            </li>
            <li className="py-2">
              <a
                href="https://springtura-mobile.lilicadez.com"
                target="_blank"
                rel="noreferrer"
                className="underline text-blue-600"
              >
                Springtura Mobile{" "}
                <span role="img" aria-label="flower">
                  🌸
                </span>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default ErrorPage
